.container
  @apply absolute top-[calc(100%_+_0.4rem)] w-full rounded-md bg-white py-0.4 z-10

.item
  @apply py-0.8 px-1.6 font-gilroy text-lg cursor-pointer select-none
  strong
    @apply text-secondary-700 font-regular

.focusedItem
  @apply bg-primary-100
