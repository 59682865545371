.div
  @apply flex flex-col relative min-h-[max-content]

.bounded
  @apply max-w-33.5

.divDiv
  @apply flex items-center justify-between rounded-xl [&>:not(:last-child)]:mr-0.4 bg-white shadow-[0_0_0_1px] shadow-secondary-300

.withIcon
  @apply pr-1.6

.focused
  @apply shadow-primary-500

.invalid
  @apply shadow-accent-900

.disabled
  @apply shadow-grays-300 bg-grays-100

.p
  @apply px-1.6 font-gilroy text-xs text-accent-900

.icon
  @apply min-h-1.6 min-w-1.6
