.tag
  @apply px-1.2 h-max py-0.4 w-max rounded-full bg-gradient-to-r font-gilroy text-xs overflow-hidden text-ellipsis max-h-[40px] whitespace-nowrap

.mid
  @apply from-linear-200 to-linear-300 text-text-primary

.dark
  @apply from-linear-700 to-linear-600 text-white

.symbiosis
  @apply from-secondary-100 to-secondary-100 text-text-primary

.light
  @apply bg-white text-secondary-900 border border-secondary-900
