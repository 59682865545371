.input
  @apply font-gilroy text-lg text-text-primary placeholder-text-disabled bg-transparent focus:outline-none w-full py-1 pl-1.6 rounded-xl focus:placeholder-transparent

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active
  -webkit-box-shadow: 0 0 0 50px white inset

.withoutIcons
  @apply px-1.6
