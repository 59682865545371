// Layout

.div
  @apply flex-grow bg-background-900 flex justify-center items-center px-9
  //min-height: calc(100vh - 70px)

.innerContainer
  @apply max-w-94.5 w-full h-full flex justify-between xl:justify-center

.column
  @apply flex flex-col justify-center items-center

// Headline

.headlineColumn
  @apply w-min xl:hidden

.p
  @apply prose-light font-heading mb-2

.subHeadline
  @apply pr-3.7 mt-8

// Forms

.loginHeader
  @apply font-gilroy font-heading text-headingmd text-primary-300 text-center mb-1.2

.form
  @apply max-w-38.8 w-screen mb-1.6 px-2.4 sm:px-0.8

.forgotPassword
  @apply prose-light text-right mb-3.2 pr-4 w-full

.registerHeader
  @apply font-gilroy font-heading text-headingsm text-secondary-100 text-center mb-1.2

.policies
  @apply prose-lightxs text-center max-w-34

.policiesLink
  @apply text-primary-300

.red
  @apply fill-accent-900

.green
  @apply fill-primary-900
