.form
  @apply bg-background-900 p-2.4 w-full max-w-33.5 rounded-lg

.fieldset
  @apply flex flex-col [&>:not(:last-child)]:mb-0.8

.h1
  @apply mb-2 text-center

.h1Default
  @apply font-gilroy font-heading text-headingmd text-white text-center w-full block

.h2
  @apply font-gilroy font-regular text-xs text-white ml-1.6

.buttonBounded
  @apply ml-auto
