.header
  @apply bg-white w-full h-7 flex items-center justify-between sticky top-0 z-20
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06)

.group
  @apply flex
  &>:not(:last-child)
    @apply mr-2.4 sm:mr-1.2

.leftGroup
  @apply mr-auto

.rightGroup
  @apply ml-auto


.sideElement
  @apply flex items-center justify-center

.left
  @apply mr-4 sm:mr-1.2

.right
  @apply ml-4 sm:ml-1.2
