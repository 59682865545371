.imageContainer
  @apply rounded-full overflow-clip flex justify-center items-center

.large
  @apply w-12 h-12 min-w-[12rem] min-h-12

.medium
  @apply w-8 h-8 min-w-[8rem] min-h-[8rem]

.small
  @apply w-4 h-4 min-w-4 min-h-4

.small2
  @apply w-6 h-6 min-w-[6rem] min-h-6

.extra-small
  @apply w-3.2 h-3.2 min-w-3.2 min-h-3.2

.placeholderIcon
  @apply w-full h-full from-linear-900 to-linear-800

.imageBackground
  @apply bg-white

.gradientBorder
  @apply rounded-full border-[0.5px] flex justify-center items-center overflow-clip w-full h-full

.image
  @apply h-full object-cover rounded-full text-[8px] leading-[1.2]

.clickable
  @apply cursor-pointer
