.div
  @apply flex whitespace-nowrap overflow-scroll select-none [&>:not(:last-child)]:mr-0.4
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none

.wrap
  @apply flex-wrap [&>*]:mt-0.4

.imageContainer
  @apply w-4 h-4

.small
  @apply w-3.2 h-3.2
