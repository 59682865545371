.container
  @apply px-4 py-2 2xl:px-2 sm:px-0.8 sm:pb-0.8 sm:pt-3.2

.containerLanding.containerLanding
  @apply py-0 px-4 2xl:px-2 sm:px-0.8 relative


.menuIcon
  @apply from-linear-900 to-linear-800 w-3.2 h-3.2

.a
  @apply flex relative

.logoLink
  @apply absolute left-0 right-0 ml-auto mr-auto w-max

.simbiozLogo
  @apply from-linear-900 to-linear-800 w-2.6 h-1.6 mr-0.4 sm:mr-0

.logoText
  @apply font-gilroy font-heading text-logo text-grays-900 uppercase sm:hidden

.beta
  @apply absolute -top-[10px] right-0 font-gilroy text-[8px] font-large uppercase leading-[10px] text-grays-900 sm:hidden

.centralLinkLanding
  @apply font-gilroy font-regular text-sm text-grays-900 uppercase sm:hidden hover:text-primary-900 active:text-primary-900

.simbiozLogoLanding
  @apply mr-0.4

.logoTextLanding
  @apply inline-block


.notificationDiv
  @apply relative cursor-pointer

.notificationAmount
  @apply font-gilroy font-strong text-2xs text-grays-900 absolute right-0 top-0.4 text-white bg-accent-700 rounded-full p-0.2 min-w-1.4 text-center


.avatarIcon
  @apply from-linear-900 to-linear-800 w-3.2 h-3.2

.vkLinkContainer
  @apply w-max
  &:hover, &:active
    .landingVkIcon
      @apply hidden
    .landingVkIconHover
      @apply block

.landingVkIcon
  @apply w-4 h-4 from-linear-900 to-linear-800

.landingVkIconHover
  @apply w-4 h-4 hidden
